@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,400;0,500;0,600;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
body { background-color: #fff; padding: 0px; font: 16.0px/1.5 "Crimson Pro", serif; color: #595959; font-weight: 400; margin: 0; }

.pub-row { display: flex; align-items: center; }

.social-icons a { display: inline-block; height: 2.4rem; width: 2.4rem; background-color: transparent; color: #13294B !important; border-radius: 100%; text-align: center; font-size: 1.15rem; line-height: 2.5rem; margin-right: 1rem; }

.social-icons a:last-child { margin-right: 0; }

.social-icons a:hover { background-color: white; color: black; transform: scale(1.2); }

.icon-button { background-color: white; border-radius: 3.6rem; cursor: pointer; display: inline-block; font-size: 2.0rem; height: 3.6rem; line-height: 3.6rem; margin: 0 5px; position: relative; text-align: center; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; width: 3.6rem; }

.icon-button span { border-radius: 0; display: block; height: 0; left: 50%; margin: 0; position: absolute; top: 50%; -webkit-transition: all 0.3s; -moz-transition: all 0.3s; -o-transition: all 0.3s; transition: all 0.3s; width: 0; }

.icon-button:hover span { width: 3.6rem; height: 3.6rem; border-radius: 3.6rem; margin: -1.8rem; }

.twitter span { background-color: #4099ff; }

.facebook span { background-color: #4286f4; }

.linkedin span { background-color: #0077B5; }

.github span { background-color: #000; }

email { font-size: 14px; font-family: "Ubuntu Mono"; }

.icon-button i { background: none; color: white; height: 3.6rem; left: 0; line-height: 3.6rem; position: absolute; top: 0; -webkit-transition: all 0.3s; -moz-transition: all 0.3s; -o-transition: all 0.3s; transition: all 0.3s; width: 3.6rem; z-index: 10; }

.icon-button .icon-twitter { color: #4099ff; }

.icon-button .icon-facebook { color: #4286f4; }

.icon-button .icon-github { color: #000; }

.icon-button .icon-linkedin { color: #0077B5; }

.icon-button:hover .icon-twitter, .icon-button:hover .icon-facebook, .icon-button:hover .icon-github, .icon-button:hover .icon-linkedin { color: white; }

autocolor { color: #595959; }

lightonly { visibility: visible; }

darkonly { visibility: hidden; }

education { font-weight: 500; font-size: 115%; }

papertitle { font-weight: 600; font-size: 100%; }

.image { border-radius: 0.35em; border: 0; display: inline-block; position: relative; }

.image:before { -moz-transition: opacity 0.2s ease-in-out; -webkit-transition: opacity 0.2s ease-in-out; -ms-transition: opacity 0.2s ease-in-out; transition: opacity 0.2s ease-in-out; border-radius: 0.35em; content: ''; display: block; height: 100%; left: 0; opacity: 0.5; position: absolute; top: 0; width: 100%; }

.image.avatar { border-radius: 100%; }

.image.avatar:before { display: none; }

.image.avatar img { border-radius: 100%; width: 45%; padding: 20px; }

#header .image.avatar { margin: 0 0 1em 0; width: 8.00em; }

h3, h4, h5, h6 { font-weight: 600; color: #13294B; margin: 0 0 20px; }

h1 { font-weight: 500; color: #13294B; margin: 0 0 20px; }

h2 { color: #13294B; font-weight: 500; margin: 2px 0px 15px; font-size: 157%; }

p, ul, ol, table, pre, dl { margin: 0 0 20px; }

h1, h2, h3 { line-height: 1.1; }

h1 { font-size: 28px; }

a { color: #39c; text-decoration: none; }

a:hover { color: #069; }

a small { font-size: 11px; color: #777; margin-top: -0.3em; display: block; }

a:hover small { color: #777; }

.wrapper { width: 960px; margin: 0 auto; }

blockquote { border-left: 1px solid #e5e5e5; margin: 0; padding: 0 0 0 20px; font-style: italic; }

code, pre { font-family: Monaco, Bitstream Vera Sans Mono, Lucida Console, Terminal, Consolas, Liberation Mono, DejaVu Sans Mono, Courier New, monospace; color: #333; }

pre { max-width: 500px; padding: 8px 15px; background: #f8f8f8; border-radius: 5px; border: 1px solid #e5e5e5; overflow-x: auto; }

table { width: 100%; border-collapse: collapse; }

th, td { text-align: left; padding: 5px 10px; border-bottom: 1px solid #e5e5e5; }

dt { color: #444; font-weight: 500; }

th { color: #444; }

img { max-width: 100%; }

header { width: 232px; float: left; position: fixed; -webkit-font-smoothing: subpixel-antialiased; padding-top: 5.5em; text-align: center; }

header ul { list-style: none; height: 40px; padding: 0; background: #f4f4f4; border-radius: 5px; border: 1px solid #e0e0e0; width: 270px; }

header li { width: 89px; float: left; border-right: 1px solid #e0e0e0; height: 40px; }

header li:first-child a { border-radius: 5px 0 0 5px; }

header li:last-child a { border-radius: 0 5px 5px 0; }

header ul a { line-height: 1; font-size: 11px; color: #999; display: block; text-align: center; padding-top: 6px; height: 34px; }

header ul a:hover { color: #999; }

header ul a:active { background-color: #f0f0f0; }

strong { color: #222; font-weight: 500; }

header ul li + li + li { border-right: none; width: 89px; }

header ul a strong { font-size: 14px; display: block; color: #222; }

section { width: 650px; float: right; padding-top: 1.2em; padding-bottom: 50px; }

small { font-size: 11px; }

hr { border: 0; background: #e5e5e5; height: 1px; margin: 0 0 20px; }

footer { width: 232px; float: left; position: fixed; bottom: 30px; -webkit-font-smoothing: subpixel-antialiased; text-align: center; }

@media print, screen and (max-width: 960px) { .pub-row { display: flex; align-items: center; } div.wrapper { width: auto; margin: 0; } header, section, footer { float: none; position: static; width: auto; } header { padding-top: 80px; } section { border: 1px solid #e5e5e5; border-width: 1px 0; padding: 20px 0; margin: 0 0 20px; } header a small { display: inline; } header ul { position: absolute; right: 50px; top: 52px; } .image.avatar img { border-radius: 100%; width: 117px; padding: 20px 20px 20px 20px; } section { padding:20px 20px }}

@media print, screen and (max-width: 960px) { body { word-wrap: break-word; padding: 0px; } .pub-row { display: flex; align-items: center; } pre, code { word-wrap: normal; } }

@media print, screen and (max-width: 480px) { .pub-row { display: block; align-items: center; } body { padding: 0px; } header ul { width: 99%; } header li, header ul li + li + li { width: 33%; } .image.avatar img { border-radius: 100%; width: 95px; padding: 20px; } }

@media print { body { padding: 0.4in; font-size: 12pt; color: #444; } }

.biblink { text-decoration: none; display: inline; margin-right: 0px; }

.fakelink { text-decoration: none; cursor: pointer; }

.bibref { font-size: 70%; margin-top: 10px; margin-left: 0px; display: none; font-family: monospace; }

/*# sourceMappingURL=style.css.map */